import { PageContextProps } from 'application/contexts/PageContext';
import { queryCmsContent } from './_api';

export const getPageContentFromCms = async (url: string, pageContext: Partial<PageContextProps>): Promise<Content.PageContent> => {
	const query = `{
		page: page {
			id
			name
			documentType
			url
			properties
			template
		}
		site: page(path:"/") {
			properties 
		}
		footerHeading: field(path:"/", field: "footerHeading") 
		footerContent: field(path:"/", field: "footerContent") 
		adobeTracking: field(path:"/", field: "adobeTracking") 
		
	}`;
	const data = await queryCmsContent({
		query,
		pageContext,
		url,
	});
	const { footerHeading, footerContent } = data ?? {};
	const pageContent: Content.PageContent = {
		...data,
		footer: {
			heading: footerHeading?.footerHeading || null,
			content: footerContent?.footerContent || null,
		},
	};
	return pageContent;
};
