import { getPageContent } from 'application/repositories/pageContentRepository';
import Error404 from 'features/Errors/404';
import ErrorGeneric from 'features/Errors/Generic';
import { ApiRedirect } from 'helpers/apiRedirect';
import getConfig from 'next/config';
import logger from 'utilities/logger';

type PageErrorProps = {
	content: Content.PageContent;
	page: Content.Page;
	site: Content.PageContent['site'];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	root: any;
	host: string;
	statusCode: number;
	statusMessage: string;
	error: string;
};

const Error = (pageProps: PageErrorProps): unknown => {
	const { statusCode, statusMessage, error } = pageProps ?? {};
	return statusCode === 404 ? <Error404 {...pageProps} /> : <ErrorGeneric {...{ statusCode }} {...{ statusMessage }} {...{ error }} />;
};

Error.getInitialProps = async (context) => {
	const { req, res, err, preview, previewData } = context;
	const { publicRuntimeConfig } = getConfig() ?? {};
	const { DOMAIN } = publicRuntimeConfig ?? {};
	const originalHost = req?.headers['x-original-host'];
	const hostName = originalHost ? originalHost : DOMAIN;

	const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
	const statusMessage = res ? res.statusMessage : err ? err.message : null;

	if (err instanceof ApiRedirect) {
		res.writeHead(err?.statusCode, {
			'Cache-Control': 'no-cache, no-store, must-revalidate',
			Expires: '0',
			Pragma: 'no-cache',
			Location: err.url || '',
		});
		res.end();
	}
	if (statusCode === 404) {
		const path = '/404';
		const pageContext = { host: hostName, preview, previewData };
		try {
			const contentPromise = getPageContent({
				url: path,
				pageContext,
			});

			return {
				statusCode,
				statusMessage,
				error: err,
				content: await contentPromise,
				host: hostName,
			};
		} catch (error) {
			logger.error(error);
			throw error;
		}
	} else {
		return {
			statusCode,
			statusMessage,
			error: err,
		};
	}
};

export default Error;
