import { FooterFeature } from 'features/Layouts/Footer';
import { HeaderFeature } from 'features/Layouts/Header';
import { LayoutFeature } from 'features/Layouts/Layout/LayoutFeature';
import { ContentPageFeature } from 'features/PageTypes/ContentPage';
import { DXPContentPage } from 'pages/_app';

const Error404: DXPContentPage<PageProps> = (pageProps) => {
	const { page } = pageProps ?? {};
	return (
		<LayoutFeature {...pageProps}>
			<HeaderFeature />
			<ContentPageFeature {...page} />
			<FooterFeature />
		</LayoutFeature>
	);
};

type PageProps = {
	content: Content.PageContent;
};

export default Error404;
