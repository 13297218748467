import classNames from 'classnames';
import { Heading, HeadingLevel, HeadingStyle } from 'components/1-atoms/Heading';
import { Picture2 } from 'components/1-atoms/Media/Picture2/Picture2';
import { RichText, RichTextModal } from 'components/1-atoms/RichText';
import React, { useEffect, useState } from 'react';
import { aspectRatioAsNumber } from 'utilities/aspectRatioAsNumber';
import { sanitizeAspectRatio } from 'utilities/sanitizeAspectRatio';
import styles from './HeaderCard.module.scss';

export interface HeaderCardProps {
	className?: string;
	image?: UI.Image;
	imagePosition?: 'Left' | 'Right';
	imageSize?: string;
	video?: React.ReactNode;
	hideImageOnSmallScreen?: boolean;
	imageDisplayStyle?: UI.ImageDisplayStyle;
	noTopMargin?: boolean;
	heading?: string;
	headingStyle?: HeadingStyle;
	headingLevel?: HeadingLevel;
	teaser?: string;
	text?: string;
	modals?: RichTextModal[];
	style?: UI.BackgroundStyle;
	aspectRatio?: '16:9' | '1:1';
	children?: React.ReactElement | React.ReactElement[];
	infoRight?: { topText: string; bottomText: string };
}
// choose to hide image on small screens - default move to top like teaser
export const HeaderCard: React.FC<HeaderCardProps> = ({
	className,
	image,
	imagePosition = 'Left',
	imageSize,
	imageDisplayStyle = 'contain',
	video,
	hideImageOnSmallScreen = false,
	noTopMargin = false,
	heading,
	headingStyle = 'xl',
	headingLevel = 'h1',
	teaser,
	text,
	modals,
	style = 'White',
	aspectRatio,
	children,
	infoRight,
}) => {
	const {	properties } = image ?? {};
	const aspectRatioSanitized = sanitizeAspectRatio({ aspectRatio, defaultRatio: '16:9' });
	const selectedAspectRatio = aspectRatioAsNumber(aspectRatio);
	const [cropFactor, setCropFactor] = useState<number>(1);

	useEffect(() => {
		if (properties === undefined) return;

		const imageAspectRatio = parseInt(properties.umbracoHeight) / parseInt(properties.umbracoWidth);

		if (selectedAspectRatio !== 1) return;

		if (imageAspectRatio < selectedAspectRatio) {
			setCropFactor(2);
		}
	}, [selectedAspectRatio, properties]);

	return (
		<div
			className={classNames(
				styles.HeaderCard,
				styles[`HeaderCard___${imagePosition}`],
				styles[`HeaderCard___${style}`],
				{ [styles.HeaderCard___noTopMargin]: noTopMargin },
				className,
			)}
		>
			<div
				className={classNames(
					styles.HeaderCard_content,
					style === 'ThemeWhite' ? 'u-bg-color--white' : `u-bg-color--${style?.toLowerCase()}`,
					infoRight && styles.HeaderCard_content___withInfoRight,
				)}
			>
				<div>
					<Heading className={styles.HeaderCard_heading} style={headingStyle} headingLevel={headingLevel}>
						{heading}
					</Heading>

					{teaser && <div className={styles.HeaderCard_teaser}>{teaser}</div>}
					{text && <RichText content={text} modals={modals} />}
				</div>

				{infoRight && (
					<div className={styles.HeaderCard_infoRight}>
						<p className={styles.HeaderCard_infoRightText}>{infoRight?.topText}</p>
						<p>{infoRight?.bottomText}</p>
					</div>
				)}

				{children}
			</div>

			{(image || video) && (
				<div
					className={classNames(
						styles.HeaderCard_media,
						hideImageOnSmallScreen ? styles.HeaderCard_media___HideImage : '',
						styles[`HeaderCard_media___${aspectRatioSanitized}`],
					)}
				>
					{image ? (
						<Picture2
							altText={image.altText}
							aspectRatio={selectedAspectRatio}
							focalPoint={image.focalPoint}
							isCover={imageDisplayStyle === 'cover'}
							isFullHeight
							padding={!imageDisplayStyle.includes('Padding') ? 'none' : imageDisplayStyle.includes('Small') ? 'small' : 'large'}
							pictureClassName={styles.HeaderCard_picture}
							sizes={`(max-width: 768px) 100vw, (max-width: 1420px) 50vw, ${700 * cropFactor}px`}
							url={image.url}			
						/>
					) : (
						video
					)}
				</div>
			)}
		</div>
	);
};
