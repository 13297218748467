import { HeaderCard } from 'designsystem/components/3-organisms/HeaderCard';
import { NextPage } from 'next';
import logger from 'utilities/logger';

const ErrorGeneric: NextPage<PageProps> = (pageProps) => {
	const { statusCode, statusMessage } = pageProps;
	logger.info('Rendering Generic Error with SSG/SSR GENERIC');

	return <HeaderCard heading={statusCode.toString()} text={statusMessage} />;
};

type PageProps = {
	statusCode?: number;
	statusMessage?: string;
	error?: unknown;
};

export default ErrorGeneric;
