import { HeaderCard } from 'designsystem/components/3-organisms/HeaderCard';
import { ContentPage } from 'designsystem/components/5-templates/ContentPage';
import { ModuleFeature } from 'features/Modules/DynamicModule';

export const ContentPageFeature: React.FC<Content.ContentPage> = (content) => {
	const { heading, contentSection } = content.properties ?? {};

	return (
		<ContentPage pageWidth="Small" additionalTop={{ bottom: <div></div> }} additionalTopSettings={{ additionalNoPadding: true }}>
			<HeaderCard heading={heading && heading} noTopMargin>
				{contentSection.map((module, index) => (
					<ModuleFeature key={index} contentModule={module} />
				))}
			</HeaderCard>
		</ContentPage>
	);
};
